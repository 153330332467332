import gql from 'graphql-tag';

const singlePatternOtherAttributesFragment = gql`
    fragment SinglePatternOtherAttributesFragment on OtherAttributesNode {
        id
        antiStatic
        antiStaticCertificateFile
        antiStaticConductive
        antiStaticConductiveCertificateFile
        antiBacterial
        antiBacterialCertificateFile
        bacterialResistance
        bacterialResistanceCertificateFile
        mildewResistance
        mildewResistanceMethod
        mildewResistanceCertificateFile
        pinkStainResistance 
        pinkStainResistanceCertificateFile
        cytotoxicity 
        cytotoxicityCertificateFile
        irritationAndSkinSensitization 
        irritationAndSkinSensitizationCertificateFile
        cleanability
        cleanibilityMethod 
        cleanabilityCertificateFile
        hydrocarbonResistance
        hydrocarbonResistanceMethod
        hydrocarbonResistanceMethodCertificateFile
        oilResistance
        sulfideStaining
        sulfideStainingCertificateFile
        heavyMetals
        heavyMetalsCertificateFile
        phthalateFreeProduct
        phthalateFreeProductCertificateFile
        hydrolisis
        hydrolisisMethod
        hydrolisisMethodCertificateFile
        heatBuildup
        heatBuildupMethod
        heatBuildupMethodCertificateFile
        accousticalAttenuation 
        accousticalAttenuationMethod 
        accousticalAttenuationMethodCertificateFile
        soundAbsorption
        soundAbsorptionMethod
        soundAbsorptionMethodCertificateFile
        foamLamination
        thermoformed
        electricSealable 
        impermeable
        impermeableCertificateFile
        temperatureReductionTreatment
        temperatureReductionTreatmentCertificateFile
        denimStainResistance
        denimStainResistanceCertificateFile
        stainResistanceInHealthcareEnvironment
        stainResistanceInHealthcareEnvironmentCertificateFile
        ruleMed
        certificateDomain
    }
`;

export default singlePatternOtherAttributesFragment;